header{
    position: absolute;
    padding: 30px 0;
    height: 155px;
    width: 100%;
    top: 0;
    background-color: rgba($color: $branco, $alpha: 0.9);
    .menu{
        margin-top: 40px;
        ul{
            padding: 0;
            li{
                display: inline-block;
                a{
                    color: $azul_escuro;
                    font-weight: 700;
                    padding: 7px 14px;
                    &:hover{
                        color: $laranja;
                    }
                }
                .actived{
                    color: $laranja;
                }
                ul{
                    width: 100%;
                    background-color: #fff;
                    left: 0;
                    margin-top: -2px;
                    position: absolute;
                    text-align: center;
                    padding-top: 6px;
                    padding-bottom: 6px;
                    //min-width: 222px;
                    overflow: hidden;
                    // SOLUÇÃO p/ transição height 0 p/ auto ////////
                    transform: scaleY(0);
                    transform-origin: top;
                    opacity: 0;
                    transition: transform .3s ease;
                    ////////////////////////////////////////////////
                    img{
                        margin-top: -20px;
                        margin-bottom: -7px;
                    }
                    span{
                        display: inline-block;
                        width: 5px;
                        height: 5px;
                        background-color: $laranja;
                        border-radius: 50%;
                        margin-bottom: 11px;
                    }
                    li{
                        list-style: none;
                        line-height: 14px;
                        margin-top: -14px;
                        a{
                            display: block;
                            padding: 5px;
                            font-size: 11px;
                            width: 100%;
                            text-align: center;
                            text-transform: uppercase;
                            &:hover{
                                color: $laranja;
                            }
                        }
                    }
                }
                .sub-monitore{
                    padding-right: 202px;
                }
                .sub-servicos{
                    padding-left: 177px;
                }
                &:hover{
                    ul{
                        transform: scaleY(1);
                        opacity: 1;
                        z-index: 9999;
                    }
                }
                .actived-sub{
                    transform: scaleY(1);
                    opacity: 1;
                }
            }
        }
    }
    .redes{
        padding: 0;
        margin-bottom: 5px;
        li{
            display: inline-block;
            width: calc(95%/3);
            margin: -1px;
            a{
                display: block;
                padding: 2px;
                border: 2px solid $laranja;
                text-align: center;
                height: 22px;
                border-radius: 5px;
                color: $laranja;
                i{
                    font-size: 12px;
                    vertical-align: super;
                }
                &:hover{
                    background-color: $laranja;
                    color: $branco;
                }
            }
        }
    }
    .login{
        background-color: $laranja;
        color: $branco;
        border-radius: 10px;
        padding: 8px 5px;
        display: block;
        width: 100%;
        font-size: 11px;
        text-align: center;
        line-height: 13px;
    }
}
.header-top{
    top: -160px;
    transition: all ease .3s;
}
.header-fixo{
    top: 0;
    position: fixed;
    z-index: 999;
    padding: 10px 0;
    height: 90px;
    border-bottom: 5px solid $laranja;
    transition: all ease .3s;
    .menu {
        margin-top: 20px;
        ul{
            li{
                ul{
                    display: none;
                }
            } 
         }
    }
    .logo{
        img{
            width: 58%;
        }
    }
}