@media only screen and (max-width: 768px) {
    .d-mobile {
        display: block !important;
    }
    .d-desk {
        display: none !important;
    }
    .banner-geral{
        height: 140px;
        background-position: -109px 0;
    }
    .banner-geral-mobile{
        .row{
            display: block;
        }
    }
    header{
        padding: 15px 0;
        height: 84px;
        .toggle{
            text-align: right;
            i{
                font-size: 27px;
                margin-top: 11px;
            }
        }
        .menu{
            margin-top: 0;
            background-color: #fff;
            z-index: 999;
            max-height: 0;
            overflow: hidden;
            transition: all .3s ease-in;
            ul{
                text-align: center;
                li{
                    display: block;
                    a{
                        padding: 10px 14px;
                        display: block;
                    }
                    ul{
                        position: relative;
                        margin-top: -5px;
                        overflow: hidden;
                        max-height: 0;
                        opacity: 0;
                        transition: transform .3s ease;
                        img{
                            margin-top: -20px;
                            margin-bottom: 0;
                        }
                        span{
                            display: inline-block;
                            width: 5px;
                            height: 5px;
                            background-color: $laranja;
                            border-radius: 50%;
                            margin-bottom: 11px;
                        }
                        li{
                            list-style: none;
                            line-height: 14px;
                            margin-top: -14px;
                            a{
                                display: block;
                                padding: 5px;
                                font-size: 11px;
                                width: 100%;
                                text-align: center;
                                text-transform: uppercase;
                                &:hover{
                                    color: $laranja;
                                }
                            }
                        }
                    }
                    .sub-monitore{
                        padding-right: 0;
                    }
                    .sub-servicos{
                        padding-left: 0;
                    }
                    &:hover{
                        ul{
                            max-height: 500px;
                            opacity: 1;
                            z-index: 9999;
                        }
                    }
                    .actived-sub{
                        max-height: 500px;
                        opacity: 1;
                    }
                }
            }
        }
        .menu-ativo{
            max-height: 570px;
        }
    }

    .main {
        margin-top: 85px;
    }

    .slider-home {
        .item {
            min-height: 100%;
        }
        .owl-nav {
            display: none !important;
        }
    }

    .PID {
        margin-top: 0;
        .title-pid {
            margin-bottom: 0;
            text-align: center;
            h2{
                font-size: 25px;
                line-height: 33px;
            }
            p{
                text-align: center;
                margin-top: 0;
                padding-right: 0;
            }
            .subtitle{
                font-size: 14px;
                line-height: 20px;
                text-align: left;
            }
            img {
                width: 100%;
            }
            a{
                margin-bottom: 20px;
            }
        }
        .video{
            margin-bottom: 20px;
            h3{
                margin-top: 0;
                margin-bottom: 14px;
                font-size: 21px;
            }
        }
        .como-funciona{
            ul{
                text-align: center;
                li{
                    width: 42%;
                    margin-right: 10px;
                    margin-left: 10px;
                }
            }
        }
    }

    .servicos-home {
        ul{
            li{
                width: 45%;
                padding: 0 10px;
                h4{
                    font-size: 15px;
                }
                p{
                    font-size: 10px;
                    line-height: 13px;
                }
            }
        }
    }

    .numeros {
        padding: 17px 0;
        .content{
            text-align: center;
            justify-content: center;
        }
        .single-skill{
            width: 50%;
        }
        .progress-h4 {
            font-size: 17px;
            margin-top: -38px;
            margin-bottom: 23px;
        }
    }
    
    .solucoes ul li {
        width: 100%;
    }

    .blog-home {
        .title-geral{
            padding: 35px 0 0;
        }
        .content-blog {
            margin-bottom: 35px;
        }
    }

    .form-orcamento {
        margin-top: 50px;
        margin-bottom: 50px;
        .title-form{
            margin-top: 0;
            margin-bottom: 25px;
            margin-left: 0;
            line-height: 35px;
            text-align: center;
            font-size: 28px;
        }
        .form-content{
            margin-top: 30px;
            form h5 {
                font-size: 13px;
            }
        }
    }

    .PID_interna{
        .title-pid {
            text-align: center;
            h2{
                font-size: 28px;
                margin-bottom: 20px;
            }
        }
        .img {
            text-align: center;
            margin-top: 0;
            margin-bottom: 50px;
        }
        .formas-acesso{
            padding-top: 45px;
            h2{
                line-height: 39px;
                text-align: center;
            }
            ul{
                margin-top: 50px;
                li{
                    width: 38%;
                    margin-right: 15px;
                    margin-left: 15px;
                }
            }
        }
    }

    .monitore-cloud{
        margin-top: 30px;
        text-align: center;
        h1 {
            font-size: 33px;
        }
        ul li {
            width: 45%;
            padding: 15px;
            h5{
                font-size: 16px;
            }
            p{
                font-size: 12px;
                line-height: 13px;
            }
        }
    }

    .scanfacial-page {
        margin-top: 30px;
        ul{
            margin-bottom: 20px;
            li{
                font-size: 16px;
            }
        }
        .scan-vigilancia{
            margin-top: 50px;
            margin-bottom: 25px;
            .texto-two-col{
                -webkit-column-count:1; /* Chrome, Safari, Opera */
                -moz-column-count:1;    /* Firefox */
                column-count:1;         /* padrão */
                column-gap: 0; // espaço entre colunas
                text-align: center;
            }
        }
        .icons-scan{
            li{
                width: 45%;
                padding: 0 15px;
                h5{
                    font-size: 16px;
                }
                p{
                    font-size: 11px;
                    line-height: 13px;
                }
            }
        }
    }

    .monitoramento-alarme{
        margin-top: 30px;
        text-align: center;
        h1 {
            font-size: 28px;
            line-height: 40px;
            margin-bottom: 20px;
        }
        .a-distancia{
            h3{
                font-size: 36px;
                line-height: 44px;
                margin-left: 0;
            }
            ul{
                text-align: left;
            }
        }
        
    }

    .servicos-page-personalizacao {
        padding: 50px 0;
        text-align: center;
        h4{
            text-align: left;
        }
        ul{
            text-align: left;
        }
    }

    .blog-page {
        padding: 0 0 50px 0;
        .noticias .compartilhar a i {
            font-size: 25px;
        }
        .sideblog .anuncio-blog {
            img{
                min-width: 100%;
            }
        }
    }

    .contato-page{
        padding: 30px 15px;
        .localizacao{
            .end{
                margin-bottom: 40px;
            }
        }
    }

    footer{
        .logo{
            text-align: center;
        }
        .menu-footer, .servicos-footer{
            padding-left: 30px;
        }
    }

    .zap {
        bottom: 15px;
        background-color: #157640;
        border: 0;
        width: auto;
        padding: 5px 6px;
        right: 58px;
        p{
            display: none;
        }
        img{
            margin: 0;
            width: 35px;
            height: 35px;
        }
    }

    .bot_top{
        margin-right: 15px;
        margin-bottom: 15px;
        a{
            width: 30px;
            height: 30px;
            i{
                margin-top: 3px;
            }
        }
    }
}