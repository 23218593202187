.servicos-page-controle{
    padding: 50px 0;
    h2{
        text-transform: uppercase;
        font-size: 35px;
        font-weight: 700;
    }
    p{
        line-height: 30px;
    }
}

.servicos-page-manutencao{
    padding: 50px 0;
    h2{
        font-size: 35px;
        font-weight: 700;
    }
    p{
        line-height: 27px;
    }

    ul{
        li{
            line-height: 27px;
        }
    }

    img{
        margin-bottom: 30px;
    }
}

.BCKP-servicos-page-locacao{
    margin-top: 80px;
    .txt-inicio{
        margin-bottom: 40px;
        h1{
            text-transform: uppercase;
            font-size: 35px;
            font-weight: 700;
        }
    }
    
    .quadro{
        padding: 40px 0;
        border-radius: 30px;
        border: 2px solid $cinza_medio;
        box-shadow: 10px 10px 20px $cinza_claro;
        margin-bottom: 20px;
        .title{
            color: $laranja;
            text-transform: uppercase;
            font-size: 30px;
            font-weight: 900 !important;
            margin-left: 45px;
        }
        .separador-quadro{
            border-right: 1px solid $cinza_medio;
        }
        .padding-quadro{
            padding-left: 50px;
        }
        ul{
            padding: 0;
            li{
                list-style: none;
                margin: 20px 0;
                img{
                    margin-right: 15px;
                    width: 25px;
                }
                span{
                    font-size: 25px;
                }
            }
        }
    }
    .orcamento-link{
        text-align: center;
        margin-bottom: 100px;
        a{
            display: inline-block;
            padding: 15px 40px;
            background-color: $verde;
            color: $branco;
            text-align: center;
            font-weight: 700;
            border-radius: 40px;
            font-size: 25px;
        } 
    }
    .solicite-link-servico{
        display: inline-block;
        padding: 15px 40px;
        background-color: $verde;
        color: $branco;
        text-align: center;
        font-weight: 700;
        border-radius: 40px;
        font-size: 20px;
    }
    .melhores{
        margin-bottom: 40px;
        h3{
            color: $azul_escuro;
            font-size: 35px;
            text-transform: uppercase;
            margin-bottom: 25px;
            span{
                color: $laranja;
                font-weight: 700;
            }
        }
    }
}

.servicos-page-locacao{
    padding: 50px 0;
    h2{
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 900;
        color: $laranja;
        margin-bottom: 30px;
    }
    p{
        strong{
            font-weight: 900;
            color: $azul_escuro;
        }
    }
    h3{
        color: $azul_escuro;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 25px;
        span{
            font-weight: 700 !important;
        }
    }
    .item{
        margin-top: 30px;
        margin-bottom: 30px;
        .icone{
            margin-bottom: 15px;
        }
    }
}
#ModalCotacao{
    .modal-dialog{
        margin: 80px auto;
        .modal-content{
            border-radius: 20px;
            .modal-header{
                margin-top: -23px;
                text-align: center;
                display: block;
                border-bottom: 0;
                .modal-title{
                    display: inline-block;
                    background: $verde;
                    padding: 10px 30px;
                    border-radius: 30px;
                    margin-top: -15px;
                    font-weight: 700;
                    color: $branco;
                }
                .close{
                    background: red;
                    opacity: 1;
                    border-radius: 50%;
                    width: 28px;
                    padding-bottom: 4px;
                    color: $branco;
                    margin-right: -44px;
                }
            }
            .modal-body{
                input{
                    background-color: #f4f4f4;
                }
                textarea{
                    background-color: #f4f4f4;
                }
                .select-servicos{
                    .form-check{
                        .form-check-label {
                            cursor: pointer;
                            padding-left: 0;
                            p{
                                text-transform: uppercase;
                                font-weight: 700;
                                line-height: 15px;
                                font-size: 14px;
                                margin-top: 10px;
                            }
                        }
                        input[type=radio] {
                            margin-left: 0;
                        }
                    }
                }
            }
            .modal-footer{
                border: 0;
                .enviar{
                    background-color: $laranja;
                    cursor: pointer;
                    font-weight: 700;
                    color: $branco;
                    padding: 8px 30px;
                }
            }
        }
    } 
}


.servicos-page-personalizacao{
    padding: 50px 0;
    h2{
        text-transform: uppercase;
        font-size: 30px;
        font-weight: 900;
    }
    h4{
        color: $laranja;
        text-transform: uppercase;
        font-size: 20px;
    }
    h3{
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 900;
    }
    .item{
        margin-top: 30px;
        .icone{
            margin-bottom: 15px;
        }
    }
    
}