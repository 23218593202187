$branco: #fff;
$preto: #000;
$cinza: #888888;
$cinza_claro: #dfdfdf;
$cinza_medio: #b7b7b7;
$cinza_escuro: #58595b;
$azul: #3ec1d5;
$azul_escuro: #1b365b;
$azul_superblack: #09182d;
$laranja: #fa4c00;
$verde: #24b14b;

//CORES REDES
$face: #3b5998;
$insta: #cb38a6;
$linke: #007bb6;
$sapp: #00a02d;