.d-mobile {
	display: none !important;
}
.d-desk {
	display: block !important;
}

html,
body{
    min-height: 100%;
    height: 100%;
	color: $cinza_escuro;
	font-family: 'Muli', sans-serif;
	font-weight: 500;
}
img{
	max-width: 100%;
	max-height: 100%;
	height: auto;
}
a,
a:hover,
a:visited,
a:active{
    //color: inherit;
    text-decoration: inherit;
}
a{
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	color: $azul;
}

button{
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	cursor: pointer;
	&:focus{
		outline: 0;
	}
}

.main{
	position: relative;
	margin-top: 155px;
}

.banner-geral{
    height: 300px;
    background-size: cover;
	background-position: left;
	position: relative;
	.text-content{
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		align-content: center;
		min-height: 100%;
		position: absolute;
		.text{
			h1{
				font-size: 30px;
				text-align: left;
				text-transform: uppercase;
				line-height: 40px;
				span{
					color: $laranja;
				}
			}
		}
		.text-fundo-claro{
			h1{
				color: $azul_escuro;
			}
		}
		.text-fundo-escuro{
			h1{
				color: $branco;
			}
		}
	}
}

.form-orcamento{
	margin-top: 50px;
	margin-bottom: 50px;
	.title-form{
		text-transform: uppercase;
		color: $laranja;
		font-weight: 900;
		margin-top: 40px;
		margin-bottom: 50px;
		margin-left: 30px;
		line-height: 45px;
		text-align: center;
	}
	.img-contato{
		margin-bottom: 30px;
	}
	.form-content{
		background-color: #fafafa;
		padding: 30px;
		border-radius: 20px;
		box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.3);
		form{
			text-align: center;
			.form-control{
				background-color: #fafafa;
				height: 50px;
			}
			.form-check-input{
				padding: 0;
				margin-left: -12px;
			}
			.form-check-label{
				padding-left: 0.25rem;
			}
			h5{
				font-size: 20px;
			}
			textarea{
				background-color: #fafafa;
				height: 100px !important;
			}
			button{
				width: 100%;
				background-color: $laranja;
				color: $branco;
				font-weight: 900;
				font-size: 20px;
				padding: 18px 0;
			}
		}
	}
}

// MIXINS ///////////////////////////
@mixin transition($value) {
    -webkit-transition: all $value ease;
    transition: all $value ease;
}

@mixin backgroudsize($value) {
    -webkit-background-size: $value;
    -moz-background-size: $value;
    -o-background-size: $value;
    background-size: $value;
}
@mixin gradient($angle, $gradient1, $gradient2){
    background-image: -webkit-gradient(linear, $angle, from($gradient1), to($gradient2));
    background-image: -webkit-linear-gradient($angle, $gradient1, $gradient2);
    background-image:    -moz-linear-gradient($angle, $gradient1, $gradient2);
    background-image:     -ms-linear-gradient($angle, $gradient1, $gradient2);
    background-image:      -o-linear-gradient($angle, $gradient1, $gradient2);
    background-image:         linear-gradient($angle, $gradient1, $gradient2);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=$angle,StartColorStr='#{$gradient1}', EndColorStr='#{$gradient2}');
}
///////////////////////////////////

.center-this{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}
.center-column{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}
.center-between{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: center;
}
.align-center-left{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: baseline;
	align-content: center;
}
.align-center-right{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;
	align-content: stretch;
}
.align-start-left{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
}
.align-end-bottom-right {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: flex-end;
	align-content: flex-end;
}
.center-space-around{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: stretch;
}
.align-bottom-center{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: flex-end;
	align-content: center;
}