footer{
    background-color: $azul_superblack;
    padding: 40px 0;
    border-top: 7px solid $laranja;
    .logo{
        margin-bottom: 30px;
    }
    .menu-footer{
        ul{
           padding: 0;
            li{
                a{
                    color: $branco;
                    &:hover{
                        color: $laranja;
                    }
                }
                ul{
                    display: none;
                }
            } 
        }
        
    }
    .servicos-footer{
        h5{
            color: $laranja;
        }
        ul{
            padding: 0;
            li{
                a{
                    color: $branco;
                    &:hover{
                        color: $laranja;
                    }
                }
            }
        }
    }
    .fone{
        color: $branco;
        font-size: 30px;
        span{
            font-size: 15px;
        }
    }

    .redes-footer{
        padding: 0;
        margin: 15px 0;
        li{
            display: inline-block;
            width: 25px;
            margin: -1px;
            a{
                display: block;
                padding: 4px 2px 0 2px;
                border: 1px solid $branco;
                text-align: center;
                height: 25px;
                border-radius: 5px;
                color: $branco;
                i{
                    font-size: 12px;
                    vertical-align: super;
                }
                &:hover{
                    background-color: $branco;
                    color: $azul_superblack;
                }
            }
        }
    }
    .contato-footer{
        padding: 0;
        li{
            list-style: none;
            padding: 10px 0;
            .icon{
                width: 23px;
                float: left;
                i{
                    color: $laranja;
                    vertical-align: top;
                    font-size: 20px;
                    margin-top: 5px;
                }   
            }
            
            span{
                display: inline-block;
                margin-left: 20px;
                color: $branco;
            }
        }
    }
    .copy{
        color: $branco;
        font-size: 10px;
    }
}
.zap{
    position: fixed;
    right: 18px;
    bottom: 118px;
    background-color: #157640;
    border: 2px solid $branco;
    border-radius: 10px;
    display: flex;
    width: 162px;
    padding: 3px 10px;
    text-align: right;
    p{
        color: $branco;
        margin-bottom: 0;
        line-height: 12px;
        font-size: 11px;
    }
    img{
        margin: -8px -18px -10px 10px;
        width: 43px;
        height: 43px;
    }
}
.bot_top{
    display: none;
    right: 0;
    bottom: 0;
    position: fixed;
    //float: right;
    margin-right: 20px;
    margin-bottom: 40px;
    z-index: 9999;
    a{
        text-align: center;
        display: block;
        width: 40px;
        height: 40px;
        color: $branco;
        //border-radius: 5px;
        background-color: $azul;
        line-height: 5px;
        opacity: 0.5;
        i{
            margin-top: 7px;
            font-size: 25px;
        }
        &:hover{
            opacity: 1;
        }
    }
}