.contato-page{
    padding: 100px 0;
    .texto-form{
        h2{
            font-weight: 700;
            font-size: 30px;
            text-transform: uppercase;
        }
        p{
            font-size: 18px;
            color: $preto;
        }
        ul{
            padding: 0;
            li{
                list-style: none;
                margin-bottom: 20px;
                i{
                    color: $laranja;
                }
            }
            .fone{
                font-weight: 700;
                font-size: 30px;
                span{
                    font-size: 12px;
                }
            }
            .mail{
                margin-left: -20px;
            }
        }
    }
    .formulario{
        .form-group{
            margin-bottom: 7px;
            input{
                background-color: #f4f4f4;
                padding: 15px;
            }
            select{
                background-color: #f4f4f4;
                height: 55px;
            }
            textarea{
                background-color: #f4f4f4;
                padding: 15px;
            }
            button{
                float: right;
                background-color: $laranja;
                color: $branco;
                border: 0;
                padding: 5px 40px;
            }
        }
    }
    .localizacao{
        padding: 60px 0;
        .mapa{
            strong{
                font-weight: 900;
            }
        }
    }
}