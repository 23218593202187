.banner-busca{
    background-color: $preto;
    height: 200px;
    padding-top: 50px;
    p{
        color: $branco;
        margin-bottom: 0;
    }
    h2{
        //color: $laranja;
        font-size: 60px;
        font-weight: bold;
    }
}
.background-busca{
    margin-top: 50px;
    padding-bottom: 30px;
    .link-tipo-busca{
        //color: $verde;
    }
}