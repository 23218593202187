.PID{
    .title-pid{
        h2{
            color: $laranja;
            text-transform: uppercase;
            font-size: 32px;
            strong{
                color: $azul_escuro;
            }  
        }
        
    }
    .img{
        margin-top: 100px;
        text-align: center;
        img{
            width: 55%;
        }
    }
    .formas-acesso{
        padding-top: 80px;
        h2{
            font-weight: 900;
            margin-bottom: 0;
            color: $laranja;
            text-transform: uppercase;
            line-height: 44px;
            strong{
                color: $azul_escuro;
            }
        }

        ul{
            padding: 0;
            margin-top: 70px;
            text-align: center;
            li{
                vertical-align: top;
                display: inline-block;
                width: 25%;
                text-align: center;
                //padding: 10px;
                margin-right: 20px;
                .ico{
                    height: 125px;
                    padding-bottom: 15px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: flex-end;
                    align-content: stretch;
                }
                p{
                    text-transform: uppercase;
                    font-size: 13px;
                    line-height: 15px;
                }
            }
        }
    }

    .depoimentos{
        margin-top: 70px;
        h2{
            text-transform: uppercase;
            color: $azul_escuro;
            text-align: center;
            font-weight: 900;
        }
        .carrousel-depo-pid{
            .item{
                padding: 40px;
                text-align: center;
                img{
                    width: 60%;
                    display: inline-block;
                    margin-bottom: 10px;
                }
                h5{
                    text-transform: uppercase;
                    margin-top: 10px;
                    font-weight: 900;
                    font-size: 17px;
                }
                p{
                    font-size: 13px;
                    line-height: 17px;
                }
            }
            .owl-nav{
                display: block !important;
                width: 100%;
                .owl-prev{
                    position: absolute;
                    top: 35%;
                    i{
                        font-size: 35px;
                        padding-left: 15px;
                    }
                }
                .owl-next{
                    position: absolute;
                    top: 35%;
                    right: 0;
                    i{
                        font-size: 35px;
                        padding-right: 15px;
                    }
                }
            }
        }
    }

    .contato-pid{
        margin-top: 100px;
        margin-bottom: 50px;
        h2{
            text-transform: uppercase;
            color: $laranja;
            font-weight: 900;
            margin-top: 40px;
            margin-bottom: 50px;
            margin-left: 30px;
            line-height: 45px;
            text-align: center;
        }
        .img-contato{
            margin-bottom: 30px;
        }
        .form-scan{
            background-color: #fafafa;
            padding: 30px;
            border-radius: 20px;
            box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.3);
            form{
                text-align: center;
                .form-control{
                    background-color: #fafafa;
                    height: 50px;
                }
                .form-check-input{
                    padding: 0;
                    margin-left: -12px;
                }
                .form-check-label{
                    padding-left: 0.25rem;
                }
                h5{
                    font-size: 20px;
                }
                textarea{
                    background-color: #fafafa;
                    height: 100px !important;
                }
                button{
                    width: 100%;
                    background-color: $laranja;
                    color: $branco;
                    font-weight: 900;
                    font-size: 20px;
                    padding: 18px 0;
                }
            }
        }
    }
}