.monitoramento-page{
    margin-top: 80px;
    h2{
        line-height: 40px;
        margin-bottom: 30px;
        span{
            font-weight: 700;
            color: $laranja;
        }
    }
    img{
        margin-bottom: 80px;
    }

    .icones-monitor{
        margin-bottom: 50px;
        .item{
            text-align: center;
            padding: 15px 40px;
            img{
                margin-bottom: 20px;
            }
            p{
                text-transform: uppercase;
                font-weight: 700;
                font-size: 21px;
                line-height: 25px;
            }
        }
    }
}

.monitore-cloud{
    margin-top: 80px;
    margin-bottom: 50px;
    h1{
        text-transform: uppercase;
        color: $azul_escuro;
        strong{
            color: $laranja;
            font-weight: 900 !important;
        }
    }
    p{
        margin-top: 30px;
    }

    ul{
        padding: 0;
        li{
            display: inline-block;
            width: 30%;
            padding: 20px;
            text-align: center;
            vertical-align: top;
            .ico{
                height: 70px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: flex-end;
                align-content: stretch;
                margin-bottom: 10px;
            }
            h5{
                text-transform: uppercase;
                color: $azul;
                font-weight: 900;
                font-size: 18px;
            }
            p{
                margin-top: 0;
                font-size: 12px;
            }
        }
    }
}

.monitoramento-alarme{
    margin-top: 80px;
    h1{
        text-transform: uppercase;
        font-size: 36px;
        line-height: 50px;
        span{
            color: $laranja;
            font-weight: 900;
        }
    }
    p{
        font-size: 15px;
        line-height: 30px;
    }

    h2{
        text-transform: uppercase;
        color: $azul_escuro;
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .icones-monitor{
        padding: 0;
        margin-top: 70px;
        text-align: center;
        .item{
            vertical-align: top;
            display: inline-block;
            text-align: center;
            .icon{
                height: 125px;
                padding-bottom: 15px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: flex-end;
                align-content: stretch;
            }
            p{
                font-weight: 900;
                text-transform: uppercase;
                font-size: 15px;
                line-height: 20px;
            }
        }
    }

    .a-distancia{
        margin-top: 50px;
        margin-bottom: 80px;
        h3{
            text-transform: uppercase;
            font-size: 40px;
            color: $azul_escuro;
            line-height: 55px;
            margin-top: 65px;
            margin-bottom: 30px;
            margin-left: 20px;
        }
        p{
            font-weight: 900;
            text-transform: uppercase;
            font-size: 15px;
            line-height: 20px;
        }
        ul{
            li{
                margin-bottom: 10px;
            }
        }
    }
}