.scanfacial-page{
    margin-top: 80px;
    img{
        margin-bottom: 30px;
    }

    h3{
        text-transform: uppercase;
        font-weight: 900;
        //margin-bottom: 30px;
        color: $laranja;
        font-size: 30px;
        margin-bottom: 20px;
    }

    ul{
        margin-bottom: 50px;
        li{
            margin-bottom: 15px;
            font-size: 18px;
        }
    }

    .scan-vigilancia{
        margin-top: 80px;
        margin-bottom: 80px;
        h2{
            text-transform: uppercase;
            color: $laranja;
            text-align: center;
            font-weight: 900;
            margin-bottom: 30px;
            font-size: 38px;
        }
        .texto-two-col{
            -webkit-column-count:2; /* Chrome, Safari, Opera */
            -moz-column-count:2;    /* Firefox */
            column-count:2;         /* padrão */
            column-gap: 40px; // espaço entre colunas
            text-align: justify;
            font-size: 15.5px;
            line-height: 30px;
        }
    }

    .icons-scan{
        padding: 0;
        text-align: center;
        li{
            vertical-align: top;
            display: inline-block;
            width: 30%;
            padding: 0 30px;
            .icon{
                height: 100px;
                display: flex;
                justify-content: center;
	            align-items: flex-end;
                position: relative;
                img{
                    width: auto; /* to keep proportions */
                    height: auto; /* to keep proportions */
                    max-width: 100%; /* not to stand out from div */
                    max-height: 100%; /* not to stand out from div */
                    margin-bottom: 15px;
                }
            }
            h5{
                text-transform: uppercase;
                font-weight: 700;
            }
            p{
                font-size: 12px;
            }
        }
    }
}