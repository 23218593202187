.slider-home{
    .item{
        min-height: calc(100vh - 150px);
        //min-height: 100vh - 150px;
        background-size: cover;
        background-position: center;
        .text-content{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            min-height: 100%;
            position: absolute;
            .text{
                opacity: 0;
                margin-left: -150px;
                transition: all 1s ease-out;
                h2{
                    font-size: 38px;
                    text-align: left;
                    text-transform: uppercase;
                    line-height: 45px;
                    span{
                        color: $laranja;
                    }
                }
                button{
                    font-size: 14px;
                    background-color: $laranja;
                    padding: 8px 20px;
                    color: $branco;
                    border-radius: 20px;
                    margin-top: 15px;
                    display: inline-block;
                    border: 0;
                    &:hover{
                        background-color: $azul_escuro;
                    }
                }
            }
            .text-fundo-claro{
                h2{
                    color: $azul_escuro;
                }
            }
            .text-fundo-escuro{
                h2{
                    color: $branco;
                }
            }
        }
    }
    .active{
        .item{
            .text-content{
                .text{
                    opacity: 1;
                    margin-left: 0;
                }
            }
        }
    }
    .owl-nav{
        width: 97%;
        button:focus {
            outline: 0;
        }
        .owl-prev{
            opacity: 0.5;
            left: 3%;
            position: absolute;
            top: 45%;
            border: 2px solid $laranja;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            i{
                font-size: 50px;
                color: $laranja;
                margin-top: -6px;
                margin-left: -4px;
            }
            &:hover{
                opacity: 1;
            }
        }
        .owl-next{
            opacity: 0.5;
            right: 3%;
            position: absolute;
            top: 45%;
            border: 2px solid $laranja;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            i{
                font-size: 50px;
                color: $laranja;
                margin-top: -6px;
                margin-right: -4px;
            }
            &:hover{
                opacity: 1;
            }
        }
    }
}

.PID{
    //margin-top: 50px;
    .title-pid{
        padding-top: 35px;
        padding-bottom: 35px;
        margin-bottom: 40px;
        img{
            width: 84%;
        }
        h2{
            text-align: left;
            font-weight: 900;
            margin-bottom: 0;
            //color: $laranja;
            text-transform: uppercase;
            line-height: 40px;
            font-size: 40px;
        }
        .subtitle{
            font-weight: 900;
            color: $laranja;
            text-transform: uppercase;
            font-size: 18px;
            margin-top: 10px;
        }
        p{
            font-size: 15px;
            margin-top: 25px;
            padding-right: 20px;
            line-height: 27px;
        }
        .btn-saiba-mais{
            font-size: 13px;
            background-color: $laranja;
            padding: 5px 20px;
            color: $branco;
            border-radius: 20px;
            margin-top: 15px;
            display: inline-block;
            &:hover{
                background-color: $azul_escuro;
            }
        }
    }
    .video{
        text-align: center;
        h3{
            margin-top: 70px;
            color: $azul_escuro;
            margin-bottom: 40px;
        }
    }
    .icons-pid{
        margin-bottom: 50px;
        .item{
            .box{
                width: 100%;
                padding: 30px;
                background-color: $azul_escuro;
                text-align: center;
                min-height: 252px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                align-content: stretch;
            }
            .txt{
                font-weight: 900;
                text-align: center;
                line-height: 25px;
                font-size: 18px;
                margin-top: 15px;
                text-transform: uppercase;
            }
        }
    }

    .como-funciona{
        background-size: cover;
        background-position: center;
        //height: 500px;
        padding-top: 40px;
        padding-bottom: 20px;
        .content-txt{
            h2{
                text-transform: uppercase;
                color: $branco;
                strong{
                    color: $laranja;
                    font-weight: 900;
                }
            }
            .txt{
                color: $branco;
                margin-top: 20px;
                line-height: 28px;
            }
        }
        ul{
            padding: 0;
            margin-top: 50px;
            li{
                vertical-align: top;
                display: inline-block;
                width: 20%;
                text-align: center;
                //padding: 10px;
                margin-right: 20px;
                .ico{
                    height: 125px;
                    padding-bottom: 15px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: flex-end;
                    align-content: stretch;
                }
                p{
                    text-transform: uppercase;
                    font-size: 13px;
                    color: $branco;
                }
            }
        }
    }
}

.video-home{
    margin-bottom: 30px;
    .txt{
        padding-left: 60px;
       h3{
            text-transform: uppercase;
            font-size: 23px;
            font-weight: 700;
            line-height: 30px;
            margin-bottom: 15px;
            span{
                color: $laranja;
            }
        }
        p{
            font-size: 19px;
            line-height: 32px;
        }
    }
}

.vantagens-pid{
    background-size: cover;
    background-position: center;
    padding-top: 50px;
    padding-bottom: 30px;
    h3{
        color: $laranja;
        font-size: 30px;
        text-align: center;
        font-weight: 700;
        margin-bottom: 50px;
    }
    ul{
        padding: 0;
        li{
            vertical-align: top;
            display: inline-block;
            width: calc(85%/2);
            text-align: center;
            color: $branco;
            margin: 0 16px 15px;
            padding: 0 15px;
            .img{
                height: 125px;
            }
            p{
                text-transform: uppercase;
                font-size: 17px;
                line-height: 21px;
            }
        }
    }
    .img-right{
        padding-left: 40px;
    }
}

.servicos-home{
    margin-top: 30px;
    margin-bottom: 40px;
    .title-geral{
        padding: 35px 15px;
        text-align: center;
        h2{
            font-weight: 700;
            margin-bottom: 30px;
        }
        p{
            font-size: 19px;
        }
    }
    ul{
        padding: 0;
        text-align: center;
        a{
            color: $cinza_escuro;
        }
        li{
            vertical-align: top;
            display: inline-flex;
            text-align: center;
            width: calc(90%/3);
            padding: 0 15px;
            min-height: 326px;
            position: relative;
            flex-direction: column;
            align-items: center;
            .icone{
                width: 100%;
                height: 155px;
                position: relative;
                display: flex;
                justify-content: center;
                img{
                    position: absolute;
                    bottom: 0;
                    width: auto; /* to keep proportions */
                    height: auto; /* to keep proportions */
                    max-width: 100%; /* not to stand out from div */
                    max-height: 100%; /* not to stand out from div */
                    margin-bottom: 15px;
                    transition: all ease .3s;
                }
            }
            h4{
                text-transform: uppercase;
                font-weight: 700;
            }
            p{
                font-size: 12px;
            }
            button{
                background: transparent;
                border: 1px solid $cinza_medio;
                border-radius: 30px;
                padding: 5px 10px;
                font-size: 10px;
                position: absolute;
                bottom: 0;
            }
            &:hover{
                .icone{
                    img{
                        bottom: 20px;
                    }
                }
                button{
                    background-color: $azul;
                    color: $branco;
                }
            }
        }
    }
}

.numeros{
    background-size: cover;
    background-position: center;
    padding: 70px 0;

    .single-skill{
        width: 20%;
        display: flex;
        justify-content: center;
        .progress-circular{
            width: 166px;
            input{
                color: $branco !important;
                font-size: 30px !important;
                font-weight: 500 !important;
            }
        }
    }
    
    .progress-h4 {
        text-align: center;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 20px;
    }
}

.solucoes{
    ul{
        padding: 0;
        text-align: center;
        li{
            vertical-align: top;
            display: inline-block;
            text-align: center;
            width: calc(90%/3);
            padding: 0 15px;
            .icone{
                height: 155px;
                position: relative;
                display: flex;
                justify-content: center;
                img{
                    position: absolute;
                    bottom: 0;
                    width: auto; /* to keep proportions */
                    height: auto; /* to keep proportions */
                    max-width: 100%; /* not to stand out from div */
                    max-height: 100%; /* not to stand out from div */
                    margin-bottom: 15px;
                }
            }
            h5{
                text-transform: uppercase;
                font-weight: 700;
                font-size: 26px;
            }
            p{
                font-size: 13px;
            }
        }
    }
}

.blog-home{
    margin-bottom: 50px;
    .title-geral{
        padding: 35px 0;
        text-align: center;
        h2{
            font-weight: 700;
            margin-bottom: 30px;
        }
        p{
            font-size: 19px;
        }
    }

    .content-blog{
        padding-bottom: 30px;
    }

    .blog-text h4 a {
        color: #444;
        text-decoration: none;
      }
      
      .blog-text h4 {
        color: #444;
        margin-bottom: 15px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 22px;
      }
      
      .blog-btn {
        border-bottom: 1px dotted #444;
        color: #444;
        text-decoration: none;
      }
      
      .blog-btn {
        border-bottom: 1px dotted #444;
        color: #444;
        display: inline-block;
        padding: 0 1px 5px 0;
        position: relative;
        text-decoration: none;
      }
      
      .blog-btn {
        position: relative;
      }
      
      .blog-btn::after {
        content: "\f178";
        font-family: fontawesome;
        position: absolute;
        right: -20px;
        top: 1px;
        transition: all 0.3s ease 0s;
      }
      
      .blog-btn:hover::after {
        right: -30px;
      }
      
      .blog-btn:hover {
        color: #333;
        text-decoration: none;
      }
      
      .blog_meta span.date_type i {
        margin-left: 5px;
      }
      
      .blog-meta span.comments-type {
        margin-left: 5px;
      }
      
      .blog-meta span i {
        padding-right: 10px;
      }
      
      .blog-content .blog-meta {
        border-bottom: 1px dotted #333;
      }
      
      .blog-meta {
        border-bottom: 1px dotted #fff;
        padding: 10px 0;
      }
      
      .comments-type>a, .date-type, .blog-meta span.comments-type {
        color: #333;
        letter-spacing: 1px;
        margin-right: 5px;
      }
      
      .blog-meta .comments-type i {
        padding-right: 0 !important;
      }
      
      .blog-content-right .comments-type>a, .blog-content-right .date-type, .blog-content-right .blog-meta span.comments-type, .blog-content-right .blog-text p {
        color: #fff;
        letter-spacing: 1px;
      }
      
      .single-blog .ready-btn {
        border: 1px solid #444;
        border-radius: 30px;
        color: #444;
        cursor: pointer;
        display: inline-block;
        font-size: 10px;
        font-weight: 500;
        margin-top: 10px;
        padding: 5px 15px;
        text-align: center;
        text-transform: uppercase;
        transition: all 0.4s ease 0s;
      }
      .single-blog .ready-btn:hover {
        border: 1px solid $azul;
        color: #fff;
        background-color: $azul;
      }
      .btn-blog{
          position: absolute;
          bottom: 0;
      }
}