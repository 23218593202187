.blog-page{
    padding: 50px 0;
    .sideblog{
        .search-option {
            display: flex;
            border: 2px solid $cinza_medio;
            height: 42px;
            margin-bottom: 30px;
            background-color: #f4f4f4;
        }
        
        .search-option input {
            border: medium none;
            padding: 6px 15px;
            width: 80%;
            background-color: #f4f4f4;
        }
          
        .search-option button {
            background: transparent none repeat scroll 0 0;
            border: medium none;
            font-size: 20px;
            padding: 5px 23px 8px 23px;
        }
          
        .search-option button:hover {
            color: #3ec1d5;
        }
    
        .recent-post{
            .recent-single-post{
                border-bottom: 1px solid $cinza_medio;
                display: flex;
                padding: 15px 0;
                .post-img{
                    width: 30%;
                }
                .pst-content{
                    width: 70%;
                    padding-left: 20px;
                    p{
                        line-height: 15px;
                        margin-bottom: 5px;
                        a{
                            text-transform: uppercase;
                            font-weight: 700;
                            font-size: 14px;
                            color: $cinza_escuro;
                        }
                    }
                    .leia{
                        border: 1px solid #999;
                        padding: 5px 10px;
                        font-size: 10px;
                        border-radius: 20px;
                        &:hover{
                            background-color: $azul;
                            color: $branco;
                        }
                    }
                }
            }
        }
        .tags{
            margin-top: 50px;
            h5{
                text-transform: uppercase;
                font-weight: 700;
                font-size: 25px;
            }
            a{
                border: 1px solid #999;
                padding: 5px 10px;
                font-size: 10px;
                border-radius: 20px;
                text-transform: uppercase;
                margin-right: 5px;
                margin-bottom: 7px;
                display: inline-block;
                &:hover{
                    background-color: $azul;
                    color: $branco;
                }
            }
        }
        .anuncio-blog{
            margin-top: 50px;
        }
    }

    .noticias{
        .compartilhar{
            float: right;
            margin-bottom: 8px;
            span{
                display: inline-block;
                font-size: 11px;
                text-transform: uppercase;
                margin-right: 5px;
            }
            a{
                margin-left: 5px;
                i{
                    font-size: 20px;
                }
            }
            .facebook-share-button{
                color: $face;
            }
            .whatsapp-share-button{
                color: $sapp;
            }
            .linkedin-share-button{
                color: $linke;
            }
        }
        .single-blog{
            margin-bottom: 40px;
            .blog-meta{
                padding-top: 10px;
                padding-bottom: 20px;
                .date-type{
                    font-size: 14px;
                    i{
                        margin-right: 5px;
                    }
                }
            }
            .blog-text{
                h4{
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 30px;
                    a{
                        color: $cinza_escuro;
                    }
                }
                p{
                    a{
                        color: $azul;
                    }
                }
            }
            .ready-btn{
                border: 1px solid #999;
                padding: 5px 15px;
                font-size: 11px;
                border-radius: 20px;
                &:hover{
                    background-color: $azul;
                    color: $branco;
                }
            }
        }
        .pag_links{
            .pagination{
                li{
                    a{
                        border: 1px solid #d4d4d4;
                        padding: 5px;
                        display: inline-block;
                        width: 35px;
                        text-align: center;
                    }
                }
                .active{
                    a{
                        background-color: $azul;
                        color: $branco;
                        cursor: default;
                    }
                }
            }
        }
    }
}
